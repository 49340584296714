import { render, staticRenderFns } from "./CityG2b.vue?vue&type=template&id=5f029762&scoped=true&"
import script from "./CityG2b.vue?vue&type=script&lang=js&"
export * from "./CityG2b.vue?vue&type=script&lang=js&"
import style0 from "./CityG2b.vue?vue&type=style&index=0&id=5f029762&scoped=true&lang=stylus&"
import style1 from "./CityG2b.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f029762",
  null
  
)

export default component.exports